@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap);
/* LIGHT */ /* REGULAR */ /* MEDIUM */ /* BOLD */

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
